export const environment = {
    production: false,
    environment: 'TANGIBLE',
    firebaseConfig: {
        apiKey: "AIzaSyD5RXxKVcqFhM7sgD_qFYcLFGBMEHcH_Y4",
        authDomain: "sharescapeio-tangible.firebaseapp.com",
        projectId: "sharescapeio-tangible",
        storageBucket: "sharescapeio-tangible.appspot.com",
        messagingSenderId: "915667983764",
        appId: "1:915667983764:web:97250023d87684ad5f8381"
    },
    hasura: {
        https: 'https://tangible-api.sharescape.io/v1/graphql',
        wss: 'wss://tangible-api.sharescape.io/v1/graphql',
    },
    apiUrl: 'https://tangible-api.sharescape.io',
    logToConsole: false,
};
